export const environment = {
    production: true,
    ROOT_URL: '',
    APP_URL: 'https://staging1.fyle.tech/app',
    SENTRY_DSN_EXTENSION: 'https://fe28dccdead245d1ac89e987f863b626@o341960.ingest.sentry.io/5790026',
    ENABLE_SENTRY_TRACING: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.3,
    ENVIRONMENT: 'staging',
    EXTENSION_NETLIFY_URL: 'https://extension.fyle.tech',
    RELEASE: '38bfc287c78fd187292c433fde147ded2439f1d6',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe54',
    MIXPANEL_PROJECT_TOKEN: 'dce787f3a74f5ede8264af230a4b4652',
    USE_MIXPANEL_PROXY: 'true',
    ENABLE_MIXPANEL: 'true',
    ROUTER_API_ENDPOINT: 'https://staging-accounts.fyle.tech',
  };